@import '../../variables.scss';

#footer {
    background-color: #546E7A;
    color: white;
    text-align: center;
    padding: 20px 0 5px 0;
}

#contact {
    margin: 30px;
    text-decoration: none;
}


