@import '../../variables.scss';

#skills {
    background-color: #EEB4AA;
}

.skills-title {
    color: white;
}

