@import '../../variables.scss';

#banner {
    background-color: #48D8A3;
    color: white;
    padding: 50px;
}

#header-title {
    font-size: 50px;
}

.flower {
    width: 150px;
    height: auto;
}



