@import '../../variables.scss';


#about {
    background-color: #F5E6E0;

    .links {
        color: black;
    }    
}

.section-header {
    font-family: 'Encode Sans SC', sans-serif;
    font-weight: 700px;
}

.about-title {
    color: #F0535C;
}

.profile-pic {
    width: 250px;
    height:auto;
}

.headline {
    font-family: 'Arvo', serif;
}

a.btn {
    background-color: #A1EACD;
    color: black;
    font-family: 'Fira Code', monospace;
    font-size: 15px;
    border-color: #A1EACD;
    margin: 5px;
}
