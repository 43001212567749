@import '../../variables.scss';

.navbar {
    background-color: #3B5EED;

    #navbar-brand {
        color: white;
        font-family: 'Hammersmith One', sans-serif;
        font-size: 1.50rem;
    }

    #basic-navbar-nav {

        .navbar-nav {

            .nav-link {
                color: white;
                text-align: right;
            }
        }
    }
 
}




/* MOBILE BREAKPOINT */

// @media only screen and (max-width: 600px) {

   
//   }