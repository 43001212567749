@import '../../variables.scss';



// a.btn {
//     background-color: #A1EACD;
//     margin: 10px;
//     font-size: 15px;
// }

// .button-style {
//     background-color: #A1EACD;
//     margin: 5px;
//     border-color: unset;
// }


// .content-column {
//     // padding-top: 65px;
// }

// #project-img {
//     // padding-top: 50px;
// }

.title {
    color: #EEB4AA;
    font-family: 'Arvo', serif;
}

